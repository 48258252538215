import { FlexCell, FlexRow } from "@/common/StyledComponents"
import { Badge, Checkbox, CircularProgress, Collapse, Icon, IconButton, Menu, MenuItem, Paper, Radio, Tooltip } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { checkBill, selectBill, selectBillIsSearched, selectCanMultiCheckBills, selectProjectCurrency, selectTab, selectToAllocateBills, setConfirmingRefutedBill, setDeallocatingBill, setRefutingBill, switchTab, uncheckBill } from "./actualSlice"
import { ActualHeaderLine, ActualLine } from "./ActualLine"
import { Grid } from "@mui/material"
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { formattedCurrency } from "@/utils"
import { useDispatch } from "react-redux"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { request } from "@/Api"
import ProtectedComponents from "@/auth/ProtectedComponents"
import { ACCOUNTING_BILL_STATUS_REFUTE, ACCOUNTING_BILL_STATUS_REFUTE_CONFIRMED } from "./constants"
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useSearchParams } from "react-router-dom"
import CallSplitIcon from '@mui/icons-material/CallSplit';

export function AccountingBill({billId, selectable, isLeaf, allocations, lineId}){
    const dispatch = useDispatch()
    const ref = useRef()
    const [ expanded, setExpanded ] = useState(false)
    const [ attachmentsAnchorEl, setAttachmentAnchorEl ] = useState(null)
    const [ actionsAnchorEl, setActionsAnchorEl ] = useState(null)
    const [ searchParams, setSearchParams ] = useSearchParams()

    const bill = useSelector(state => selectBill(state, billId))
    const searched = useSelector(state => selectBillIsSearched(state, billId))
    const allocated = bill && bill.accounting_bill_actual_lines.length > 0
    const projectCurrency = useSelector(selectProjectCurrency)
    
    const menuOpen = Boolean(attachmentsAnchorEl)
    const actionMenuOpen = Boolean(actionsAnchorEl)

    useEffect(()=>{
        if (!bill) {
            return
        }
        const goToBillId = searchParams.get("billId")
        
        if (goToBillId == bill.id){
            ref.current.scrollIntoView()
            if (bill.accounting_bill_actual_lines.length > 1) {
                setExpanded(true)
            }
        }
    }, [searchParams])

    if (!bill || !searched){
        return
    }

    const lineAllocation = (lineId && bill) ? bill.accounting_bill_actual_lines.find(allocation=>allocation.actual_line_id==lineId) : null
    const lineAllocatedProject = lineAllocation ? lineAllocation.actual_project : 0
    const lineAllocatedBill = lineAllocation ? lineAllocation.actual : 0

    const handleMenuOpen = (event) => {
        setAttachmentAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAttachmentAnchorEl(null)
    }

    const handleActionMenuOpen = (event) => {
        setActionsAnchorEl(event.currentTarget)
    }

    const handleActionMenuClose = () => {
        setActionsAnchorEl(null)
    }

    const hasActualLines = bill.accounting_bill_actual_lines.length > 0

    const startDeallocate = () => {
        dispatch(setDeallocatingBill(bill))
    }

    const startRefute = () => {
        dispatch(setRefutingBill(bill))
    }

    const startConfirmRefute = () => {
        dispatch(setConfirmingRefutedBill(bill))
    }

    const gotoGroup = () => {
        dispatch(switchTab(0))
        setSearchParams({billId, _timestamp: Date.now()})
    }

    return (
        <>
            <FlexRow style={{paddingBottom: 10}} ref={ref}>
                {selectable && 
                <FlexCell style={{maxWidth: 50}}>
                    <AccountingBillToggle billId={billId}/>
                </FlexCell>}
                <FlexCell minWidth={120}>
                    <Grid container alignItems={"center"} wrap="nowrap">
                        <Tooltip title={`id: ${billId}`}>
                            <Grid item><span>{bill.vendor_name}</span></Grid>
                        </Tooltip>
                        {!isLeaf && 
                        <Grid item>
                            <IconButton onClick={()=>{setExpanded(!expanded)}} disabled={!hasActualLines}>
                                <ArticleOutlinedIcon />
                            </IconButton>
                        </Grid>}
                    </Grid>
                </FlexCell>
                <FlexCell minWidth={80}><span>{bill.accounting_bill_number}</span></FlexCell>
                <FlexCell minWidth={80}><span>{bill.invoice_date}</span></FlexCell>
                <FlexCell item minWidth={400}><span>{bill.description}</span></FlexCell>
                {lineAllocation && <FlexCell item align="right"><span>{formattedCurrency(lineAllocatedBill, bill.currency, false, 2)}</span></FlexCell>}
                {lineAllocation && <FlexCell item align="right"><span>{formattedCurrency(lineAllocatedProject, projectCurrency, false, 2)}</span></FlexCell>}
                {!lineAllocation && <FlexCell item align="right"><span>{formattedCurrency(bill.actual, bill.currency, false, 2)}</span></FlexCell>}
                {!lineAllocation && <FlexCell item align="right"><span>{formattedCurrency(bill.actual_project, projectCurrency, false, 2)}</span></FlexCell>}
                { allocations &&
                <FlexCell>    
                    <FlexCell item align="right">
                    <span>
                        {formattedCurrency(
                            allocations.find(allocation=>allocation.accounting_bill_id==billId).actual, bill.currency, false, 2
                        )}
                    </span>
                </FlexCell>
                </FlexCell>}
                <FlexCell minWidth={130} style={{textAlign: "right"}}>
                    {(lineId && bill.accounting_bill_actual_lines.length > 1) &&
                        <IconButton onClick={gotoGroup}>
                            <CallSplitIcon />
                        </IconButton>
                    }
                    {(!lineId && bill.accounting_bill_actual_lines.length > 1) &&
                        <IconButton onClick={()=>{setExpanded(!expanded)}}>
                            <CallSplitIcon />
                        </IconButton>
                    }
                    {bill.status == ACCOUNTING_BILL_STATUS_REFUTE &&
                    <ProtectedComponents permission={"confirm_refuted_accounting_bill"}>
                        <IconButton onClick={startConfirmRefute}>
                            <WarningRoundedIcon color="warning"/>
                        </IconButton>
                    </ProtectedComponents>}
                    {bill.attachments.length > 0 && 
                        <AttachmentButton 
                            openMenu={handleMenuOpen} 
                            attachment={bill.attachments.length == 1 && bill.attachments[0]}
                        />
                    }
                    {allocated ?
                    <IconButton onClick={startDeallocate}>
                        <LinkOffIcon />
                    </IconButton> :
                    <>
                        {bill.status != ACCOUNTING_BILL_STATUS_REFUTE_CONFIRMED &&
                        <IconButton onClick={handleActionMenuOpen}>
                            <MoreVertIcon/>
                        </IconButton>}
                    </>
                    }
                </FlexCell>
            </FlexRow>
            {!isLeaf && 
            <Collapse in={expanded}>
                <Paper sx={{margin: "10px 10px 10px 10px", padding: 3, boxShadow: "none", overflow: "auto"}}>
                    <ActualHeaderLine billId={billId}/>
                    {bill.accounting_bill_actual_lines.map((line, index)=>
                        <ActualLine 
                            lineId={line.actual_line_id} 
                            billId={billId}
                            isLeaf={true} 
                            key={index}
                        />
                    )}
                </Paper>
            </Collapse>}
            <Menu
                id="basic-menu"
                anchorEl={attachmentsAnchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {bill.attachments.map((attachment, index)=>
                    <AttachmentMenuItem key={index} index={index} attachment={attachment} />
                )}
            </Menu>
            <Menu
                id="basic-menu"
                anchorEl={actionsAnchorEl}
                open={actionMenuOpen}
                onClose={handleActionMenuClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={startRefute}>Refute</MenuItem>
            </Menu>
        </>
    )
}

export function AccountingBillHeaderLine({selectable, isAllocating, lineId}){
    return (
        <FlexRow style={{color: "grey", paddingBottom: 20}}>
            {selectable && <FlexCell item style={{maxWidth: 50}}/>}
            <FlexCell item minWidth={120}>Vendor</FlexCell>
            <FlexCell item minWidth={80}>Invoice No.</FlexCell>
            <FlexCell item minWidth={80}>Date</FlexCell>
            <FlexCell item minWidth={400}>Notes</FlexCell>
            {lineId && <FlexCell item align="right">Bill Allocated</FlexCell>}
            {lineId && <FlexCell item align="right">Project Allocated</FlexCell>}
            {!lineId && <FlexCell item align="right">Bill Total</FlexCell>}
            {!lineId && <FlexCell item align="right">Project Total</FlexCell>}
            {isAllocating && <FlexCell item align="right">Portion of Bill</FlexCell>}
            <FlexCell item minWidth={130}> </FlexCell>

        </FlexRow>
    )
}

const AccountingBillToggle = ({billId}) => {
    const dispatch = useDispatch()
    const toAllocateBills = useSelector(selectToAllocateBills)
    const multiSelect = useSelector(selectCanMultiCheckBills)
    const Toggle = multiSelect ? Checkbox : Radio
    const handleToggle = (event, checked) => {
        checked ? dispatch(checkBill(billId)) : dispatch(uncheckBill(billId))
    }
    return (
        <Toggle 
            checked={toAllocateBills.find(b=>b.id==billId) != null}
            onChange={handleToggle} 
            size="small"
        />
    )
}

const AttachmentButton = ({openMenu, attachment}) => {
    const [ waiting, setWaiting ] = useState(false)
    const handleAttachmentClick = (event) => {
        if (attachment) {
            setWaiting(true)
            request.get(`/accounting-bill-attachment/${attachment.legal_entity_id}/${attachment.quickbooks_id}/`)
            .then(response=>{
                setWaiting(false)
                window.open(response.data, "_blank")
            })
        } else {
            openMenu(event)
        }
    }

    return (
        <>
            {waiting ?
            <CircularProgress size={25}/> :
            <IconButton onClick={handleAttachmentClick}>
                <PictureAsPdfIcon/>
            </IconButton>}
        </>
        
    )
}

const AttachmentMenuItem = ({index, attachment}) => {
    const [ waiting, setWaiting ] = useState(false)
    const handleAttachmentClick = (legalEntityId, attachmentQuickbooksId) => {
        setWaiting(true)
        request.get(`/accounting-bill-attachment/${legalEntityId}/${attachmentQuickbooksId}/`)
        .then(response=>{
            setWaiting(false)
            window.open(response.data, "_blank")
        })
    }
    return (
        <>
            {waiting ?
                <div style={{padding: 2}}>
                    <CircularProgress size={25}/>
                </div> :
                <Tooltip title={attachment.quickbooks_file_name} followCursor>
                    <MenuItem disabled={waiting} onClick={()=>{handleAttachmentClick(attachment.legal_entity_id, attachment.quickbooks_id)}}>
                            <span style={{fontSize: 12}}>{`View file ${index + 1}`}</span>
                    </MenuItem>
                </Tooltip>

            }
        </>
    )
}